import React, { useEffect } from "react";
import { IfAnyGranted } from "react-authorization";
import { makeStyles } from "@mui/styles";
import { Box, Card, CardContent, Button, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import { usePermission } from "../../context/permission";
import { useKnowledgeBase } from "../../context/knowledge_base";
import { useReferences } from "../../context/references";
import { SpinnerWithBackdrop } from "../../../shared/components/utils/_spinner";
import { API_PATH } from "../../../const/api_paths";

import Add from "./Add";
import Edit from "./Edit";
import Delete from "./Delete";
import Description from "./show/Description";
import Tags from "./show/Tags";

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
    paddingTop: 16,
  },
  header: {
    color: "#444",
    marginLeft: 20,
  },
  card: {
    minWidth: 500,
    margin: 20,
    padding: 10,
    backgroundColor: "#9bdac524",
  },
});

const KnowledgeBase = () => {
  const classes = useStyles();
  const { loading, documents, fetchKnowledgeBases } = useKnowledgeBase();
  const { atleastAngel, getMyRoles } = usePermission();
  const { myself } = useReferences();

  useEffect(() => {
    fetchKnowledgeBases();
  }, []);

  const reloadList = () => {
    fetchKnowledgeBases();
  };

  const onClickOpen = (doc) => () => {
    const encoded = window.btoa(`${window.crypto.randomUUID()}--${myself.id}`);
    const target_url = API_PATH(
      `/knowledge_bases/${doc.id}/download_file?n=${encoded}`
    );
    window.open(target_url, "_blank");
  };

  if (loading) {
    return <SpinnerWithBackdrop />;
  }

  return (
    <div className={classes.wrapper}>
      <Typography variant="h4" gutterBottom className={classes.header}>
        Frittata Knowl-EGGS Base
      </Typography>

      <IfAnyGranted expected={atleastAngel()} actual={getMyRoles()}>
        <Box display="flex" justifyContent="flex-end">
          <Add onRecordCreated={reloadList} />
        </Box>
      </IfAnyGranted>

      {documents.map((doc) => (
        <Card key={doc.id} className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h5">
              {doc.title}
            </Typography>
            <Description description={doc.description} />
          </CardContent>
          <Box display="flex" justifyContent="flex-end">
            <Tags tags={doc.tags} />

            <IfAnyGranted expected={atleastAngel()} actual={getMyRoles()}>
              <Edit item={doc} onRecordUpdated={reloadList} />
              <Delete item={doc} onRecordDeleted={reloadList} />
            </IfAnyGranted>

            <Button
              size="small"
              variant="outlined"
              endIcon={<LaunchIcon />}
              onClick={onClickOpen(doc)}
            >
              Learn More
            </Button>
          </Box>
        </Card>
      ))}
    </div>
  );
};

export default KnowledgeBase;
