import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Modal, TextField, Button } from "@mui/material";

import { doAuthenticatedPostFormData } from "../../../actions/_methods";
import { API_KNOWLEDGE_BASES } from "../../../const/api_paths";
import { useGlobal } from "../../context/global";
import { constructFormData } from "../../../utils/constructFormData";
import FileUpload from "../../../shared/components/upload/KnowledgeBase";

import Description from "./form/Description";
import Tags from "./form/Tags";

const useStyles = makeStyles({
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#f8f8ff",
    borderRadius: "5px",
  },
  save: {
    backgroundColor: "#3059fb",
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2b50e2",
    },
  },
  cancel: {
    backgroundColor: "#f3f4f6",
    color: "#616775",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#dbdcdd",
    },
  },
  header: {
    display: "flex",
    fontWeight: 500,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#FFFFFF",
    backgroundColor: "#333232",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 10,
    marginTop: 20,
    paddingTop: 20,
    borderTop: "1px solid #00000029",
  },
  error: {
    color: "#c60000",
    fontSize: 12,
    paddingTop: 4,
    textAlign: "center",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
});

const AddModal = ({ open, onRecordCreated, handleClose }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const [data, setData] = useState({});
  const [enableButton, setEnableButton] = useState(true);

  const onChangeLocal = (newData) => {
    setData({ ...data, ...newData });
  };

  const onChangeField = (e) => {
    const { name, value } = e.target;
    onChangeLocal({ [name]: value });
  };

  const onAddItem = () => {
    setEnableButton(false);

    var formData = constructFormData(data);
    doAuthenticatedPostFormData(API_KNOWLEDGE_BASES, formData, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
      catch: () => {
        setEnableButton(true);
      },
    });
  };

  const onError = (res) => {
    let errorMessage = "Unable to create:";
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += " " + res.errors.join(" ");
    }
    prompt(errorMessage, {
      variant: "error",
    });

    setEnableButton(true);
  };

  const onSuccess = (res) => {
    const message = `Successfully created ${data.title}`;
    prompt(message);

    onRecordCreated();
    setEnableButton(true);
    onClose();
  };

  const onClose = () => {
    setData({});
    handleClose();
  };

  const afterFileUpload = (file) => {
    // Append file to files array
    const files = data.files || [];
    files.push(file);
    setData({ ...data, files: files });
  };

  const afterFileRemove = (file) => {
    // Remove file from files array
    const files = data.files || [];
    files.splice(files.indexOf(file), 1);
    setData({ ...data, files: files });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.box}>
        <div className={classes.header}>Add New Knowledge Base</div>
        <div className={classes.container}>
          <div className={classes.textFieldContainer}>
            <div className={classes.wrapper}>
              <TextField
                size="small"
                variant="outlined"
                label="Title"
                name="title"
                value={data.title}
                onChange={onChangeField}
              />
            </div>
            <div className={classes.wrapper}>
              <Description
                defaultValue={data.description}
                onChange={onChangeLocal}
              />
            </div>
            <div className={classes.wrapper}>
              <Tags defaultValue={data.tags} onChange={onChangeLocal} />
            </div>
            <div className={classes.wrapper}>
              <TextField
                size="small"
                variant="outlined"
                label="Position (lower value will be displayed first)"
                name="position"
                value={data.position}
                onChange={onChangeField}
                InputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            <Box>
              <FileUpload
                afterUpload={afterFileUpload}
                afterRemove={afterFileRemove}
                inputContent="Click or drag and drop the document here"
              />
            </Box>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={classes.cancel}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={!enableButton}
              variant="contained"
              className={classes.save}
              onClick={onAddItem}
            >
              Add
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddModal;
