import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { doAuthenticatedGet } from '../../../../../actions/_methods';
import {
  ACCOUNTING_DATA,
  ACCOUNTING_PROJECT_TASKS,
  API_PATH,
  EXPORT_ACCOUNTING_PROJECT_TASKS,
} from '../../../../../const/api_paths';
import FilterBar from './FilterBar';
import ActionsBar from './ActionsBar';
import DataTable from './table/DataTable';
import CustomPagination from './CustomPagination';
import { addQueryString } from '../../../../../actions/_methods';
import { AccountingProjectTasksCtxProvider } from '../../../../../features/context/accounting_project_tasks';
import { useReferences } from '../../../../context/references';
import { useGlobal } from '../../../../context/global';

const useStyles = makeStyles({
  card: {
    border: '1px solid #00000029',
  },
  body: {
    gap: 16,
    display: 'flex',
    flexDirection: 'column',
  },
});

const headerStyles = {
  color: '#23282C',
  fontWeight: 500,
};

const AccountingTask = ({ firm_id }) => {
  const { setPageKnowledgeBaseTags } = useGlobal();
  const { organizations, firm_users, loadOrganizations, loadFirmUsers } = useReferences();

  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [firm, setFirm] = useState(null);
  const [users, setUsers] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [topLevelCategories, setTopLevelCategories] = useState([]);
  const [subLevelCategories, setSubLevelCategories] = useState([]);
  const [accountingProjectTasks, setAccountingProjectTasks] = useState([]);

  const [account, setAccount] = useState(null);
  const [location, setLocation] = useState(null);
  const [minEstHrs, setMinEstHrs] = useState(null);
  const [maxEstHrs, setMaxEstHrs] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [parentCategory, setParentCategory] = useState(null);
  const [selectedFrequencies, setSelectedFrequencies] = useState([]);

  const getFirm = (firms) => {
    const id = parseInt(firm_id, 10);
    return firms.find((firm) => firm.id === id);
  };

  const fetchTaskCategories = () => {
    doAuthenticatedGet(
      ACCOUNTING_DATA,
      {},
      {
        success: onTaskCategoriesSuccess,
        general: onTaskCategoriesSuccess,
        error: (res) => console.error(res),
      }
    );
  };

  const params = (isReset = false) => {
    return isReset
      ? {
        firm: firm_id
      }
      : {
          page: page,
          firm: firm_id,
          account: account && account.id,
          location: location && location.id,
          min_estimated_hours: minEstHrs && minEstHrs,
          max_estimated_hours: maxEstHrs && maxEstHrs,
          assigned_users: assignedUsers && assignedUsers,
          parent_task_category_id: parentCategory && parentCategory.id,
          task_category_id: subCategory && subCategory.id,
          frequencies: selectedFrequencies && selectedFrequencies,
        };
  };

  const fetchAccountingProjectTasks = (isReset = false) => {
    doAuthenticatedGet(ACCOUNTING_PROJECT_TASKS, params(isReset), {
      success: onAccountingProjectTaskSuccess,
      general: onAccountingProjectTaskSuccess,
      error: (res) => console.error(res),
    });
  };

  const onTaskCategoriesSuccess = (res) => {
    setTopLevelCategories(res.parent_categories);
    setSubLevelCategories(res.sub_categories);
  };

  const onAccountingProjectTaskSuccess = (res) => {
    setPage(res.current_page);
    setTotalPages(res.total_pages);
    setAccountingProjectTasks(res.tasks);
  };

  const onFilterApply = () => {
    setPage(1);
    fetchAccountingProjectTasks();
  };

  const onFilterReset = () => {
    setPage(1);
    setAccount(null);
    setLocation(null);
    setMinEstHrs(null);
    setMaxEstHrs(null);
    setSubCategory(null);
    setAssignedUsers([]);
    setParentCategory(null);
    setSelectedFrequencies([]);
    fetchAccountingProjectTasks(true);
  };

  const onExport = () => {
    let cleaned_filter = Object.fromEntries(Object.entries(params()).filter(([_, v]) => v != null));
    const export_url = addQueryString(`${EXPORT_ACCOUNTING_PROJECT_TASKS}.csv`, cleaned_filter);
    window.location = API_PATH(export_url);
  };

  useEffect(() => {
    setUsers(firm_users);
    setFirm(getFirm(organizations));
  }, [organizations, firm_users, firm_id]);

  useEffect(() => {
    fetchTaskCategories();
    loadOrganizations();
    loadFirmUsers(firm_id);
    setPageKnowledgeBaseTags(["Task Templates"]);
  }, []);

  useEffect(() => {
    fetchAccountingProjectTasks();
  }, [page]);

  return (
    <AccountingProjectTasksCtxProvider>
    <Card className={classes.card}>
      <CardHeader
        title={'Accounting Task Template'}
        subheader={firm?.name}
        titleTypographyProps={headerStyles}
      />
      <CardContent>
        <Box>
          <FilterBar
            firm={firm}
            users={users}
            account={account}
            location={location}
            minEstHrs={minEstHrs}
            maxEstHrs={maxEstHrs}
            setAccount={setAccount}
            setLocation={setLocation}
            subCategory={subCategory}
            setMinEstHrs={setMinEstHrs}
            setMaxEstHrs={setMaxEstHrs}
            onFilterApply={onFilterApply}
            onFilterReset={onFilterReset}
            assignedUsers={assignedUsers}
            parentCategory={parentCategory}
            setSubCategory={setSubCategory}
            setSelectedWeek={setSelectedWeek}
            setAssignedUsers={setAssignedUsers}
            setParentCategory={setParentCategory}
            topLevelCategories={topLevelCategories}
            subLevelCategories={subLevelCategories}
            selectedFrequencies={selectedFrequencies}
            setSelectedFrequencies={setSelectedFrequencies}
          />
        </Box>
        <Box className={classes.body}>
          <ActionsBar
            firm={firm}
            users={users}
            onExport={onExport}
            topLevelCategories={topLevelCategories}
            subLevelCategories={subLevelCategories}
            reloadData={fetchAccountingProjectTasks}
          />
          <DataTable
            firm={firm}
            users={users}
            topLevelCategories={topLevelCategories}
            subLevelCategories={subLevelCategories}
            reloadData={fetchAccountingProjectTasks}
            rows={accountingProjectTasks ? accountingProjectTasks : []}
          />
          <CustomPagination
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        </Box>
      </CardContent>
    </Card>
    </AccountingProjectTasksCtxProvider>
  );
};

export default AccountingTask;
