import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import InputWorkMins from "./add_comment/InputWorkMins";
import InputAdjustment from "./add_comment/InputAdjustment";
import { usePermission } from "../../../../../../context/permission";
import { IfAnyGranted } from "react-authorization";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    diplay: "flex",
    flexGrow: 1,
    marginTop: "10px",
  },
  element_row: {
    display: "flex",
    flexGrow: 1,
  },
  textfield: {
    width: "100%",
    marginRight: "10px",
  },
  card_wrapper: {
    padding: "10px",
  },
}));

export default ({ onSubmit }) => {
  const classes = useStyles();
  const [show_more, setShowMore] = useState(false);
  const { atleastAdmin, atleastManager, getMyRoles } = usePermission();

  const MoreButton = () => (
    <Button
      size="small"
      endIcon={<ArrowDropDownIcon />}
      onClick={() => setShowMore(!show_more)}
    >
      more presets
    </Button>
  );

  const LessButton = () => (
    <Button
      size="small"
      endIcon={<ArrowDropUpIcon />}
      onClick={() => setShowMore(!show_more)}
    >
      less presets
    </Button>
  );

  const CustomElements = () => (
    <Card variant="outlined" className={classes.card_wrapper}>
      <IfAnyGranted expected={atleastManager()} actual={getMyRoles()}>
        <InputWorkMins onSubmit={onSubmit} />
      </IfAnyGranted>
      <InputAdjustment
        onSubmit={onSubmit}
        label="Break in Minutes"
        fieldUnit="mins"
        commentUnit="breaks"
      />
      <InputAdjustment
        onSubmit={onSubmit}
        label="Reporting in Minutes"
        fieldUnit="mins"
        commentUnit="reporting"
      />
      <InputAdjustment
        onSubmit={onSubmit}
        label="Invoice Count"
        fieldUnit="invoices"
        commentUnit="invoices"
      />
      <InputAdjustment
        onSubmit={onSubmit}
        label="Invoice Pages"
        fieldUnit="pages"
        commentUnit="pages"
      />
      <InputAdjustment
        onSubmit={onSubmit}
        label="Rejected Pages"
        fieldUnit="pages"
        commentUnit="rejects"
      />
    </Card>
  );

  return (
    <div className={classes.wrapper}>
      {!show_more && <MoreButton />}
      {show_more && <LessButton />}
      {show_more && <CustomElements />}
    </div>
  );
};
