import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Box, Paper, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useFirmTasks } from '../../../../context/firm_tasks';
import { usePermission } from '../../../../context/permission';

const useStyles = makeStyles((theme) => ({
  box: {
    flexBasis: (props) =>
      props.showPageCount
        ? props.angelUser
          ? '85.5%'
          : '90%'
        : props.angelUser
        ? '90%'
        : '100%',
    marginRight: '2px',
  },
  paper: {
    padding: '10px',
    height: '44px',
    backgroundColor: blueGrey[200],
  },
}));

export default () => {
  const { firmTasks, preferences } = useFirmTasks();
  const showPageCount = preferences.show_page_count === 'true';
  const angelUser = usePermission()?.isAngel() ?? false;
  const classes = useStyles({ showPageCount, angelUser });

  return (
    <Box className={classes.box}>
      <Paper square className={classes.paper}>
        <Typography variant="subtitle1">
          Total No. of Tasks:
          <strong style={{ paddingLeft: '10px' }}>{firmTasks.length}</strong>
        </Typography>
      </Paper>
    </Box>
  );
};
