import React from "react";
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { makeStyles } from "@mui/styles";
import _ from "lodash";

import DueDate from "../../../board/components/_tasks/variants/_default/show/_DueDate";
import Organization from "../../../board/components/_tasks/variants/_default/show/_Organization";
import Tags from "../../../board/components/_tasks/variants/_default/show/_Tags";
import Description from "../../../board/components/_tasks/variants/_default/show/_Description";
import CategoryTooltip from "../../../../shared/components/CategoryTooltip";

const useStyles = makeStyles((theme) => ({
  inner_box: {
    display: "flex",
    marginTop: "10px",
  },
  inner_box_spaced: {
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between",
  },
}));

export default ({ taskRequest }) => {
  const classes = useStyles();
  debugger;

  const FilesAttached = () => (
    <Stack className={classes.attached_items}>
      <List className={classes.list_items}>
        {taskRequest.task_sop_upload.files.map((f) => (
          <ListItem className={classes.attachment_item}>
            <AttachFileIcon />
            <ListItemText
              primary={
                <Link
                  href={f.url}
                  color="inherit"
                  download={f.filename}
                  target="_blank"
                  textDecoration="none"
                >
                  {f.filename}
                </Link>
              }
              secondary={f.content_type}
              primaryTypographyProps={{ fontSize: "12px" }}
              secondaryTypographyProps={{ fontSize: "10px" }}
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  );

  return (
    <>
      <Box>
        <h2 className={classes.title}>{taskRequest.name}</h2>
      </Box>

      <Box sx={{ width: "fit-content" }}>
        <CategoryTooltip title={taskRequest.task_category_description}>
          <Typography variant="body2" sx={{ color: "info.dark" }}>
            {_.compact([
              taskRequest.parent_category_name,
              taskRequest.task_category_name,
            ]).join(" >> ")}
          </Typography>
        </CategoryTooltip>
      </Box>

      <Box className={classes.inner_box_spaced}>
        <DueDate task={taskRequest} />
        <Organization task={taskRequest} />
      </Box>

      <Box className={classes.inner_box}>
        <Tags task={taskRequest} />
      </Box>

      <Box className={classes.inner_box}>
        <Description
          task_description={taskRequest.description}
          minHeight={"100px"}
        />
      </Box>

      {(taskRequest.task_sop_upload?.files?.length ?? 0) > 0 && (
        <Box>
          <Box>
            <Typography variant="h6">Added SOP Files:</Typography>
          </Box>
          <Box className={classes.inner_box}>
            <FilesAttached />
          </Box>
        </Box>
      )}
    </>
  );
};
