import React, { useState } from "react";
import { Button } from "@mui/material";

import ListPresetsModal from "./_ListPresetsModal";
import SavePresetModal from "./_SavePresetModal";

export default ({ filters, setFilters, enable }) => {
  const [listPresetsOpen, setListPresetsOpen] = useState(false);
  const [savePresetOpen, setSavePresetOpen] = useState(false);

  const openLoadPresets = () => {
    setListPresetsOpen(true);
  };

  const openSavePreset = () => {
    setSavePresetOpen(true);
  };

  const resetPresets = () => {
    setFilters({ user_shift: "", changedAt: new Date().toISOString() });
  };

  const closeModals = () => {
    setListPresetsOpen(false);
    setSavePresetOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="warning"
        onClick={resetPresets}
        disabled={!enable}
      >
        Reset
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={openLoadPresets}
        disabled={!enable}
      >
        Load Presets
      </Button>
      <Button
        variant="contained"
        color="success"
        onClick={openSavePreset}
        disabled={!enable}
      >
        Save New Preset
      </Button>
      <ListPresetsModal
        open={listPresetsOpen}
        handleClose={closeModals}
        setFilters={setFilters}
      />
      <SavePresetModal
        open={savePresetOpen}
        handleClose={closeModals}
        filters={filters}
      />
    </>
  );
};
