import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Select, InputLabel, FormControl, MenuItem } from "@mui/material";

const useStyles = makeStyles({
  container: {
    marginRight: "10px",
    width: (props) => props.width,
  },
  input: {
    width: "100%",
    marginRight: "10px",
    "& > div > div": {
      padding: "8px 10px",
      fontSize: "12pt",
      minWidth: "85px",
    },
  },
});

export default ({
  name,
  title,
  default_value,
  options,
  onChange,
  exclude_none,
  width,
  presetValue,
  changedAt,
}) => {
  const classes = useStyles({ width });
  const [value, setValue] = useState(default_value);

  useEffect(() => {
    if (presetValue) {
      setValue(presetValue);
    } else {
      setValue("");
    }
  }, [presetValue, changedAt]);

  const onChangeLocal = (e) => {
    const target_value = e.target.value;
    setValue(target_value);
    onChange(sendBackData(target_value));
  };

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.input}>
        <InputLabel id="demo-simple-select-outlined-label" size="small">
          {title}
        </InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={onChangeLocal}
          label={title}
        >
          {(exclude_none == undefined || exclude_none == false) && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {options.map((o) => {
            return (
              <MenuItem
                key={`basic_filter_input_${Math.random()}`}
                value={o.value}
              >
                {o.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
