import React, { useState, useEffect } from "react";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import { useReferences } from "../../../context/references";
import FilterDropdown from "../../../board/components/shared/category/FilterDropdown";

const useStyles = makeStyles((_theme) => ({
  box: {
    display: "inline-block",
    marginRight: "10px",
    width: "305px",
  },
}));

export default ({ onChange, presetValue }) => {
  const classes = useStyles();
  const { parent_categories, sub_categories } = useReferences();
  const [parentCategory, setParentCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);

  useEffect(() => {
    setSubCategoryOptions(sub_categories);
  }, [sub_categories]);

  useEffect(() => {
    const id = presetValue?.id;
    const parentId = presetValue?.parentId;

    if (id) {
      const taskCategory = _.find(sub_categories, { id: id });
      const parentCategory = _.find(parent_categories, {
        id: taskCategory.parent.id,
      });
      handleChangeCategory({ target: { value: taskCategory } }, "subCategory");
      setSubCategoryOptions(parentCategory.children);
    } else if (parentId) {
      const parentCategory = _.find(parent_categories, { id: parentId });
      handleChangeCategory(
        { target: { value: parentCategory } },
        "parentCategory"
      );
    } else {
      onSelectNone();
    }
  }, [presetValue]);

  const handleChangeCategory = (event, type) => {
    const new_value = event.target.value === "" ? null : event.target.value;
    let data = {};

    switch (type) {
      case "parentCategory":
        setParentCategory(new_value);

        if (new_value) {
          setSubCategory(null);
          setSubCategoryOptions(new_value.children);
          data["parent_task_category_id"] = new_value.id;
          data["task_category_id"] = "";
          onChange(data);
        }
        break;

      case "subCategory":
        setSubCategory(new_value);
        if (new_value) {
          setParentCategory(new_value.parent);
          data["parent_task_category_id"] = new_value.parent.id;
          data["task_category_id"] = new_value.id;
          onChange(data);
        }
        break;

      default:
        break;
    }
  };

  const onSelectNone = () => {
    const data = {
      parent_task_category_id: "",
      task_category_id: "",
    };
    setParentCategory(null);
    setSubCategory(null);
    setSubCategoryOptions(sub_categories);
    onChange(data);
  };

  return (
    <>
      <Box className={classes.box}>
        <FilterDropdown
          width="100%"
          label="Parent Category"
          value={parentCategory}
          onSelectNone={onSelectNone}
          onChange={(e) => handleChangeCategory(e, "parentCategory")}
          options={parent_categories ? parent_categories : []}
          noOptionsMessage="No Parent Categories"
        />
      </Box>
      <Box className={classes.box}>
        <FilterDropdown
          width="100%"
          label="Sub Category"
          value={subCategory}
          onChange={(e) => handleChangeCategory(e, "subCategory")}
          options={subCategoryOptions ? subCategoryOptions : []}
          noOptionsMessage="No Sub Categories"
        />
      </Box>
    </>
  );
};
