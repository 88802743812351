import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { STYLES } from "../../../../shared/components/data_table/styles";
import DisplayElement from "../../../../shared/components/data_table/micro_components/display_element";
import { doAuthenticatedPost } from "../../../../actions/_methods";
import { API_MANAGE_TASK_REQUEST_APPROVE } from "../../../../const/api_paths";
import { API_MANAGE_TASK_REQUEST_DECLINE } from "../../../../const/api_paths";

import ActionButtons from "./_action_buttons";
import TaskRequestInfo from "./_info";

export default ({ columns, listable_columns, data, loadData }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [local_data, setLocalData] = useState({});
  const [showInfo, setShowInfo] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);

  const showApproveCheckbox = ["pending", "declined"].includes(data["status"]);

  useEffect(() => {
    setLocalData({ ...data });
  }, [data]);

  const onApproveClick = () => {
    let api_path = API_MANAGE_TASK_REQUEST_APPROVE.replace(
      ":task_request_id",
      data["id"]
    );

    setEnableButtons(false);
    doAuthenticatedPost(
      api_path,
      {},
      {
        success: onSuccessApprove,
        general: onSuccessApprove,
        error: onError,
      }
    );
  };

  const onDeclineClick = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to decline this task request?"
    );

    let api_path = API_MANAGE_TASK_REQUEST_DECLINE.replace(
      ":task_request_id",
      data["id"]
    );

    if (userConfirmed) {
      setEnableButtons(false);
      doAuthenticatedPost(
        api_path,
        {},
        {
          success: onSuccessDecline,
          general: onSuccessDecline,
          error: onError,
        }
      );
    }
  };

  const onSuccessApprove = (res) => {
    setEnableButtons(true);
    enqueueSnackbar("Task request has been approved.", { variant: "success" });
    loadData();
  };

  const onSuccessDecline = (res) => {
    setEnableButtons(true);
    enqueueSnackbar("Task request has been declined.", { variant: "success" });
    loadData();
  };

  const onError = (res) => {
    setEnableButtons(true);
    enqueueSnackbar("An ERROR occured, please try again.", {
      variant: "error",
    });
  };

  const toggleShowInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <>
      <tr onDoubleClick={toggleShowInfo}>
        <td style={STYLES.td} key={`datatable_td_${Math.random()}`}>
          {showApproveCheckbox && (
            <input
              type="checkbox"
              checked={local_data["approve"]}
              value={local_data["id"]}
              onChange={(e) => {
                setLocalData({ ...local_data, approve: e.target.checked });
              }}
            />
          )}
        </td>
        {listable_columns.map((col) => {
          return (
            <td style={STYLES.td} key={`datatable_td_${Math.random()}`}>
              <DisplayElement
                columns={columns}
                column={col["column"]}
                data={data}
              />
            </td>
          );
        })}
        <td style={STYLES.td_end} key={`datatable_td_action_buttons`}>
          <ActionButtons
            data={data}
            disabled={!enableButtons}
            onApproveClick={onApproveClick}
            onDeclineClick={onDeclineClick}
          />
        </td>
      </tr>
      {showInfo && (
        <tr>
          <td
            colSpan={listable_columns.length + 2}
            style={STYLES.td_end}
            key={`datatable_td_info${Math.random()}`}
          >
            <TaskRequestInfo taskRequest={data} />
          </td>
        </tr>
      )}
    </>
  );
};
