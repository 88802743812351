import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

import { fileSizeHumanized } from "../../../utils/fileSizeHumanized";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
  },
  box: {
    display: "flex",
    flexGrow: 1,
    marginRight: "2px",
    backgroundColor: "lightgray",
  },
  previewContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "15px",
  },
  fileInfo: {
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    marginLeft: "10px",
  },
  fileSize: {
    marginLeft: "10px",
    color: "#888",
  },
}));

const dropzoneStyles = {
  dropzone: {
    overflow: "hidden",
    minHeight: "100px",
  },
  inputLabelWithFiles: {
    marginTop: "0px",
    marginBottom: "20px",
  },
};

export default ({ afterUpload, afterRemove, inputContent }) => {
  const classes = useStyles();

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
    if (status === "done") {
      // Send the file parameter to the parent form
      afterUpload(file);
    } else if (status === "removed") {
      // Remove the file from the parent form
      afterRemove(file);
    } else if (status === "error_upload") {
      alert("Please try again...");
    }
  };

  const Preview = ({ meta, fileWithMeta }) => {
    const classes = useStyles();
    const { name, type, size } = meta;
    const { remove } = fileWithMeta;

    return (
      <div className={classes.previewContainer}>
        <div className={classes.fileInfo}>
          <AttachFileIcon />
          <span className={classes.fileName}>
            {name} ({type})
          </span>
          <span className={classes.fileSize}>{fileSizeHumanized(size)}</span>
        </div>
        <IconButton onClick={remove}>
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  return (
    <Box className={classes.box}>
      <Dropzone
        onChangeStatus={handleChangeStatus}
        accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
        maxFiles={1}
        multiple={false}
        SubmitButtonComponent={null}
        PreviewComponent={Preview}
        inputContent={inputContent}
        styles={dropzoneStyles}
      />
    </Box>
  );
};
