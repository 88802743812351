import { useFirmTasks } from '../../../../../context/firm_tasks';
import { usePermission } from '../../../../../context/permission';

export const STYLES = (theme) => {
  const { preferences } = useFirmTasks();
  const angelUser = usePermission()?.isAngel() ?? false;
  const showPageCount = preferences.show_page_count === 'true';

  return {
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    margin_bottom: {
      marginBottom: '15px',
    },
    header_row: {
      display: 'flex',
      marginBottom: '2px',
      fontSize: '9pt',
      textAlign: 'center',
      alignItems: 'bottom',
    },
    header_name: {
      flexBasis: showPageCount
        ? angelUser
          ? '35%'
          : '40%'
        : angelUser
        ? '40%'
        : '55%',
      marginRight: '2px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '20px',
    },
    header_organizational_structure: {
      flexBasis: '20%',
      marginRight: '2px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    header_due_date: {
      flexBasis: '7%',
      marginRight: '2px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    header_status: {
      flexBasis: '11%',
      marginRight: '2px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    header_assigned_to: {
      flexBasis: '12%',
      marginRight: '2px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    header_work_hours: {
      flexBasis: '5%',
      marginRight: '2px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    header_filler_hours: {
      flexBasis: '5%',
      marginRight: '2px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    header_billable_hours: {
      flexBasis: '5%',
      marginRight: '2px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
  };
};
