import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#EEDD66",
    color: "black",
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: "normal",
    border: "1px solid #EEDD66",
  },
}));

export default ({ title, placement, children }) => {
  if (!title) {
    return children;
  }

  return (
    <HtmlTooltip title={title} placement={placement}>
      {children}
    </HtmlTooltip>
  );
};
