import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Box, Typography, Paper, Divider, Button } from "@mui/material";
import { useGlobal } from "../../context/global";
import { useFirmTasks } from "../../context/firm_tasks";
import { useReferences } from "../../context/references";
import TaskCategory from "./_add_task/TaskCategory";
import InputText from "./_basic_filters/InputText";
import InputDate from "./_basic_filters/InputDate";
import InputSelect from "./_basic_filters/InputSelect";
import InputSelectMultiple from "./_basic_filters/InputSelectMultiple";
import InputPeople from "./_basic_filters/InputPeople";
// import InputAutocomplete from './_basic_filters/InputAutocomplete';
import DateRange from "./_basic_filters/DateRange";
import Reports from "./_reports/";
import Organizational from "./_add_task/Organizational";
import AccountLocation from "./_add_task/AccountLocation";
import { STATUS_OPTIONS, TEAM_OPTIONS } from "../const/ref";

export default ({
  classes,
  default_filter,
  references,
  onChange,
  no_organization,
  view_mode,
}) => {
  const { firmUsers } = useGlobal();
  const { preferences } = useFirmTasks();
  const { organizations } = useReferences();
  const [data, setData] = useState({ ...default_filter });
  // const [category_options, setCategoryOptions] = useState([]);
  const [start_date, setStartDate] = useState(
    moment().startOf("week").toDate()
  );
  const [end_date, setEndDate] = useState(moment().endOf("week").toDate());
  const [showTeam, setShowTeam] = useState(false);
  const filterWidths = view_mode !== "uncompleted" ? "15%" : "20%";

  // useEffect(() => {
  //   if (references.categories) {
  //     setCategoryOptions(references.categories);
  //   }
  // }, [references]);

  const onChangeLocal = (new_data) => {
    setData((prevData) => ({
      ...prevData,
      ...new_data,
    }));
  };

  const applyFilter = () => {
    onChange(data);
  };

  const onChangeDateRange = (selection) => {
    var new_data = {
      ...data,
      ...{
        start_date: moment(selection.startDate).format("YYYY-MM-DD"),
        end_date: moment(selection.endDate).format("YYYY-MM-DD"),
      },
    };

    setData(new_data);
    // onChange(new_data);
  };

  const onChangeOrganization = (new_data) => {
    setData({ ...data, ...new_data });
    // onChange({...data, ...new_data});
  };

  useEffect(() => {
    if (preferences) {
      setShowTeam(preferences.pass_the_baton === "true");
    }
  }, [preferences]);

  return (
    <Box>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant={"h6"}>Basic Filters</Typography>
        <Divider className={classes.margin_bottom} />
        <Box display="flex" justifyContent="space-between" width="100%" gap={2}>
          <InputText
            width={filterWidths}
            name="match_task_id"
            title="Task ID"
            onChange={onChangeLocal}
          />
          <InputText
            width={filterWidths}
            name="match_name"
            title="Task Name"
            onChange={onChangeLocal}
          />
          <InputDate
            width={filterWidths}
            name="due_date"
            title="Due Date"
            onChange={onChangeLocal}
          />
          {view_mode !== "uncompleted" && (
            <InputSelectMultiple
              width={filterWidths}
              name="match_any_status"
              title="Status"
              options={STATUS_OPTIONS}
              onChange={onChangeLocal}
              default_value={[]}
            />
          )}
          <InputPeople
            width={filterWidths}
            name="user_id"
            title="Person"
            options={firmUsers}
            onChange={onChangeLocal}
          />
          {/* <InputAutocomplete
            width={filterWidths}
            name="category_id"
            title="Category"
            options={category_options}
            onChange={onChangeLocal}
          /> */}
          <InputText name="tags" title="Tags" onChange={onChangeLocal} />
        </Box>
        <Box display="flex" className={classes.margin_top}>
          {!no_organization && (
            <Box display="inline-flex" justifyContent="space-between">
              <Organizational
                onChange={onChangeOrganization}
                options={organizations}
              />
            </Box>
          )}
          <Box display="inline-flex" justifyContent="space-between">
            <AccountLocation
              onChange={onChangeOrganization}
              firm_id={default_filter.firm_id}
              options={organizations}
            />
          </Box>
          <Box display="inline-flex">
            <TaskCategory onChange={onChangeLocal} required={false} />
          </Box>
          {showTeam && (
            <Box display="inline-flex">
              <InputSelect
                name="match_team_timezone"
                title="Team"
                options={TEAM_OPTIONS}
                onChange={onChangeLocal}
                default_value=""
              />
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.margin_top}
        >
          <DateRange
            start_date={start_date}
            end_date={end_date}
            onChange={onChangeDateRange}
          />
          <Reports data={data} view_mode={view_mode} />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="success" onClick={applyFilter}>
            Apply Filter
          </Button>
        </Box>
        <a id="reload_all_tasks" onClick={() => onChange(data)}></a>
      </Paper>
    </Box>
  );
};
