import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  Box,
  Typography,
} from '@mui/material';
import { WEEK_OPTIONS } from '../../../../board/const/ref';
import InputSelect from '../../../../board/components/_basic_filters/InputSelect';

const FilterFrequency = ({
  task,
  width,
  setSelectedWeek,
  selectedFrequencies,
  setSelectedFrequencies,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [weekOption, setWeekOption] = useState(null);

  const handleChange = (event) => {
    const value = event.target.value;

    setSelectedOptions(value);
    setSelectedFrequencies(value);
  };

  const options = [
    'Every Sunday',
    'Every Monday',
    'Every Tuesday',
    'Every Wednesday',
    'Every Thursday',
    'Every Friday',
    'Every Saturday',
    // 'First Day of Month',
    // 'Last Day of Month',
    // 'Middle of the Month',
    // 'Second Monday of the Month',
  ];

  const biweeklyOptions = [
    // 'Bi-weekly Monday',
    // 'Bi-weekly Tuesday',
    // 'Bi-weekly Wednesday',
    // 'Bi-weekly Thursday',
    // 'Bi-weekly Friday',
    // 'Bi-weekly Saturday',
  ];

  const getWeekOptionLabel = (weekOption) => {
    const chosen = _.find(WEEK_OPTIONS, { value: weekOption });
    return chosen ? chosen.label : '';
  };

  const onChangeWeekOption = (new_data) => {
    const week = new_data['weekOption'];
    setWeekOption(week);
    setSelectedWeek(week);
  };

  useEffect(() => {
    if (task && task.frequency) {
      const selected = task.frequency.map((f) => f.name);
      setSelectedOptions(selected);
      setSelectedFrequencies(selected);
    }
  }, [task]);

  useEffect(() => {
    if (selectedFrequencies && selectedFrequencies.length === 0) {
      setSelectedOptions([]);
    }
  }, [selectedFrequencies]);

  return (
    <FormControl style={{ width: width }}>
      <InputLabel size="small">Frequency</InputLabel>
      <Select
        multiple
        size="small"
        label="Frequency"
        value={selectedOptions}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 400, marginTop: 10 },
          },
        }}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedOptions.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
        <hr style={{ margin: '10px 0' }} />
        {biweeklyOptions.length > 0 && (
          <Box style={{ padding: 10 }}>
            <Typography
              variant="h6"
              style={{ width: '90%', marginTop: 10, marginBottom: 10 }}
            >
              Bi-weekly ({getWeekOptionLabel(weekOption)})
            </Typography>
            <InputSelect
              name="weekOption"
              title="Week"
              options={WEEK_OPTIONS}
              onChange={onChangeWeekOption}
              default_value={weekOption}
              exclude_none={true}
            />
          </Box>
        )}
        {biweeklyOptions.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedOptions.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterFrequency;
