import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, CardContent, Button } from '@mui/material';

import Title from './show/_Title';
import Category from './show/_Category';
import TaskCategory from './show/_TaskCategory';
import Organization from './show/_Organization';
import Billable from './show/_Billable';
import StartDate from './show/_StartDate';
import DueDate from './show/_DueDate';
import TaskSopUpload from './show/_TaskSopUpload';
import LatestSopDocuments from './show/_LatestSopDocuments';
import CreatedBy from './show/_CreatedBy';
import AssignedTo from './show/_AssignedTo';
import WorkAsOneLocations from './show/_WorkAsOneLocations';
import Tags from './show/_Tags';
import Status from './show/_Status';
import Files from './show/files/_Files';
import Rules from './show/_Rules';
import Description from './show/_Description';
import TotalPerUser from './show/_TotalPerUser';
import Stuck from './_Stuck';
import CheckList from './show/_Checklist';

import { useSelector } from 'react-redux';
import { IfAnyGranted } from 'react-authorization';
import { usePermission } from '../../../../../context/permission';
import { API_TASK_PASS_BATON } from '../../../../../../const/api_paths';
import { doAuthenticatedPut } from '../../../../../../actions/_methods';
import { useGlobal } from '../../../../../context/global';
import { useFirmTasks } from '../../../../../context/firm_tasks';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  box: {
    width: '65%',
    marginBottom: '20px',
  },
  inner_box: {
    display: 'flex',
    marginTop: '10px',
  },
  inner_box_spaced: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'space-between',
  },
  buttons_box: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .cancel-btn': {
      marginLeft: '15px',
    },
    '& .delete-btn': {
      marginLeft: '15px',
    },
  },
  stuck_button_box: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pass_baton_button: {
    backgroundColor: '#7d42a8',
    height: 36.5,
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#652f8d',
    },
  },
  footer_button_box: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,
    marginTop: 20,
    alignItems: 'center',
  },
}));

export default ({ task, onHide, onShowEdit, onTaskUpdate, previousFiles }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { preferences } = useFirmTasks();
  const { atleastManager, getMyRoles } = usePermission();
  const [data, setData] = useState({});
  const [showPassBaton, setShowPassBaton] = useState(false);
  const [availableTeam, setAvailableTeam] = useState('Bogota');
  const [availableTimezone, setAvailableTimezone] = useState('America/Bogota');

  const passBatonEndpoint = () => {
    return API_TASK_PASS_BATON.replace(':task_id', task.id);
  };

  const onPassBaton = () => {
    doAuthenticatedPut(
      passBatonEndpoint(),
      { team_timezone: availableTimezone },
      {
        success: onBatonPassSuccess,
        general: onBatonPassSuccess,
        error: (res) => alert('Please try again'),
      }
    );
  };

  const onBatonPassSuccess = () => {
    const message = `Task has been successfully passed to ${availableTeam}.`;

    prompt(message);
  };

  const onChange = (new_data) => {
    setData({ ...data, ...new_data });
  };

  useEffect(() => {
    setData(task);
  }, [task]);

  useEffect(() => {
    const teamTimezone = task.team_timezone;
    const team = teamTimezone === 'Asia/Manila' ? 'BOGOTA' : 'MANILA';
    const timezone =
      teamTimezone === 'Asia/Manila' ? 'America/Bogota' : 'Asia/Manila';

    setAvailableTeam(team);
    setAvailableTimezone(timezone);
  }, [task]);

  useEffect(() => {
    if (preferences) {
      setShowPassBaton(preferences.pass_the_baton === 'true');
    }
  }, [preferences]);

  return (
    <Card className={classes.box}>
      <CardContent>
        <Box>
          <Title task={task} />
          <Category task={task} />
          <TaskCategory task={task} />
        </Box>

        <Box className={classes.inner_box_spaced}>
          <StartDate task={task} />
          <CreatedBy task={task} />
        </Box>

        {task.task_sop_upload_url && (
          <Box className={classes.inner_box}>
            <TaskSopUpload task={task} />
          </Box>
        )}

        {task.latest_sop_documents_url && (
          <Box className={classes.inner_box}>
            <LatestSopDocuments task={task} />
          </Box>
        )}

        <Box className={classes.inner_box_spaced}>
          <DueDate task={task} />
          <Organization task={task} />
        </Box>

        <Box
          className={classes.inner_box_spaced}
          style={{ marginBottom: '20px' }}
        >
          <Status task={task} />
          <AssignedTo task={task} />
        </Box>

        <Box className={classes.inner_box}>
          <Tags task={task} />
        </Box>

        <Box className={classes.inner_box}>
          <WorkAsOneLocations task={task} />
        </Box>

        <Box className={classes.inner_box}>
          <TotalPerUser task={task} />
        </Box>

        <Box className={classes.inner_box}>
          <CheckList task={task} />
        </Box>

        <Box className={classes.inner_box}>
          <Description task_description={task.description} />
        </Box>

        <Box className={classes.inner_box}>
          <Rules task={task} />
        </Box>

        <Box className={classes.inner_box}>
          <Files files={task.files} previousFiles={previousFiles} />
        </Box>

        <Box className={classes.buttons_box}>
          <Button variant="contained" onClick={onShowEdit}>
            Edit
          </Button>
          <Button
            variant="contained"
            className="cancel-btn"
            onClick={() => onHide()}
          >
            Close
          </Button>
        </Box>

        <Box className={classes.footer_button_box}>
          <IfAnyGranted expected={atleastManager()} actual={getMyRoles()}>
            {showPassBaton && (
              <Button
                variant="contained"
                className={classes.pass_baton_button}
                onClick={onPassBaton}
              >
                PASS TO {availableTeam}
              </Button>
            )}
          </IfAnyGranted>

          <Stuck task={task} onTaskUpdate={onTaskUpdate} />
        </Box>
      </CardContent>
    </Card>
  );
};
