import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import EditModal from "./EditModal";

const useStyles = makeStyles({
  actionButton: {
    marginRight: 20,
  },
});

const Edit = ({ item, onRecordUpdated }) => {
  const classes = useStyles();
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="info"
        className={classes.actionButton}
        endIcon={<ModeEditIcon />}
        onClick={() => setShowEditModal(true)}
      >
        Edit
      </Button>
      <EditModal
        item={item}
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        onRecordUpdated={onRecordUpdated}
      />
    </>
  );
};

export default Edit;
