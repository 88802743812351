import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

import AddModal from "./AddModal";

const useStyles = makeStyles({
  addButton: {
    marginRight: 20,
  },
});

const Add = ({ onRecordCreated }) => {
  const classes = useStyles();
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.addButton}
        onClick={() => setShowAddModal(true)}
      >
        Add New Knowledge Base
      </Button>
      <AddModal
        open={showAddModal}
        handleClose={() => setShowAddModal(false)}
        onRecordCreated={onRecordCreated}
      />
    </>
  );
};

export default Add;
