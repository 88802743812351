import React from "react";
import { Box, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tagsContainer: {
    marginRight: 20,
    fontSize: 14,
    padding: 5,
  },
  tagsLabel: {
    fontWeight: "bold",
    color: "#00000085",
    marginRight: 5,
  },
  tags: {
    marginRight: 5,
  },
});

export default ({ tags }) => {
  const classes = useStyles();

  return (
    <>
      {tags.length > 0 && (
        <Box className={classes.tagsContainer}>
          <span className={classes.tagsLabel}>Tags:</span>
          {tags.map((tag) => (
            <Chip
              className={classes.tags}
              label={tag}
              color="primary"
              size="small"
            />
          ))}
        </Box>
      )}
    </>
  );
};
