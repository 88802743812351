import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  element_row: {
    display: "flex",
    flexGrow: 1,
    margin: 7,
  },
  textfield: {
    width: "100%",
    marginRight: "10px",
  },
  addButton: {
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#3587e4",
    },
  },
  minusButton: {
    fontSize: "18px",
    marginLeft: "10px",
    backgroundColor: "#c5564e",
    "&:hover": {
      backgroundColor: "#e9675e",
    },
  },
}));

export default ({ onSubmit, label, fieldUnit, commentUnit }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");

  const onClick = (e, action) => {
    if (value === "") return;

    const actionSymbol = action === "subtract" ? "-" : "+";
    const comment_value = `${actionSymbol}${value} ${commentUnit}`;
    onSubmit(comment_value);
    setValue("");
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className={classes.element_row}>
      <TextField
        label={label}
        size="small"
        type="number"
        className={classes.textfield}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">{fieldUnit}</InputAdornment>
          ),
          inputMode: "numeric",
          pattern: "[0-9]",
        }}
        value={value}
        onChange={onChange}
      />
      <Button
        variant="contained"
        size="small"
        className={classes.addButton}
        onClick={(e) => onClick(e, "add")}
      >
        +
      </Button>
      <Button
        variant="contained"
        size="small"
        className={classes.minusButton}
        onClick={(e) => onClick(e, "subtract")}
      >
        -
      </Button>
    </div>
  );
};
