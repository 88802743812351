import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/styles";
import { Box, Typography, Paper, Chip } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { useFirmTasks } from "../../../../../../context/firm_tasks";
import { usePermission } from "../../../../../../context/permission";

import { useGlobal } from "../../../../../../context/global";

const useStyles = makeStyles({
  box: {
    flexBasis: (props) =>
      props.showPageCount
        ? props.angelUser
          ? "35%"
          : "40%"
        : props.angelUser
        ? "40%"
        : "55%",
    marginRight: "2px",
  },
  paper: {
    padding: "10px",
    minHeight: "44px",
    height: "100%",
  },
  typography: {
    paddingRight: 15,
    whiteSpace: "normal",
  },
  chip: {
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#34a841",
    },
  },
  chip_tag: {
    marginRight: "2px",
  },
  locationName: {
    fontWeight: "bold",
  },
});

export default ({ task, onShowDetail }) => {
  const { getSelectedFirm } = useGlobal();
  const firmData = getSelectedFirm();
  const firmTags = firmData.tags;
  const angelUser = usePermission()?.isAngel() ?? false;
  const { preferences } = useFirmTasks();
  const showPageCount = preferences.show_page_count === "true";
  const classes = useStyles({ showPageCount, angelUser });
  const theme = useTheme();
  const [showTeam, setShowTeam] = useState(false);
  const timezone = task.team_timezone;

  const statusPaperStyle = () => {
    if (task.status === "on_going") {
      return { borderLeft: `10px solid ${theme.palette.primary.light}` };
    } else if (task.status === "paused") {
      return { borderLeft: `10px solid ${theme.palette.warning.light}` };
    } else if (task.status === "completed") {
      return { borderLeft: `10px solid ${theme.palette.success.light}` };
    } else if (task.status === "stucked") {
      return { borderLeft: `10px solid ${theme.palette.error.light}` };
    } else {
      return { borderLeft: "10px solid transparent" };
    }
  };

  const teams = {
    "America/Bogota": {
      team: "🇨🇴 Bogota",
      color: "secondary",
    },
    "America/New_York": {
      team: "🇺🇸 New York",
      color: "primary",
    },
    "Asia/Manila": {
      team: "🇵🇭 Manila",
      color: "info",
    },
  };

  const onClickID = () => {
    const windowGlobal = typeof window !== "undefined" && window;
    windowGlobal.open(`/app/task/${task.id}`, "_blank");
  };

  useEffect(() => {
    if (preferences) {
      setShowTeam(preferences.pass_the_baton === "true");
    }
  }, [preferences]);

  const TagList = (tags) => {
    if (tags.length == 0) {
      return null;
    } else {
      return (
        <>
          {tags.map((tag) => {
            return (
              <Chip
                label={tag}
                color="primary"
                size="small"
                variant="outlined"
                className={classes.chip_tag}
              />
            );
          })}
        </>
      );
    }
  };

  const isR365Firm = firmTags.includes("R365");
  const isChouxBoxFirm = firmTags.includes("ChouxBox");
  const taskLocationSlug = task.location_slug;

  return (
    <Box className={classes.box}>
      <Paper
        square
        className={classes.paper}
        style={statusPaperStyle()}
        onDoubleClick={onShowDetail}
      >
        <Typography className={classes.typography}>
          {(isR365Firm || isChouxBoxFirm) && task.location_name ? (
            <>
              <span className={classes.locationName}>{task.location_name}</span>{" "}
              |&nbsp;
              {task.name}
            </>
          ) : (
            task.name
          )}
          <br />
          <Chip
            label={"#" + task.id}
            size="small"
            color="success"
            className={classes.chip}
            onClick={onClickID}
          />
          &nbsp;
          {isR365Firm && taskLocationSlug && (
            <Chip
              label={"R365 Instance"}
              component="a"
              target="_blank"
              size="small"
              color="primary"
              className={classes.chip}
              href={`https://${taskLocationSlug}.restaurant365.com/`}
              icon={<LaunchIcon />}
              clickable
            />
          )}
          &nbsp;
          {isChouxBoxFirm && taskLocationSlug && (
            <Chip
              label={"ChouxBox Uploads"}
              component="a"
              target="_blank"
              size="small"
              color="primary"
              className={classes.chip}
              href={`https://app.chouxbox.com/restaurants/${taskLocationSlug}/uploads`}
              icon={<LaunchIcon />}
              clickable
            />
          )}
          &nbsp;
          {showTeam && (
            <Chip
              label={teams[timezone].team}
              color={teams[timezone].color}
              size="small"
              variant="outlined"
            />
          )}
          &nbsp;
          {task.tags.length > 0 && TagList(task.tags)}
        </Typography>
      </Paper>
    </Box>
  );
};
