import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Modal, TextField } from "@mui/material";

import { API_REPORT_PRESETS } from "../../../../const/api_paths";
import { doAuthenticatedPost } from "../../../../actions/_methods";
import { useGlobal } from "../../../context/global";
import moment from "moment";

const useStyles = makeStyles({
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#f8f8ff",
    borderRadius: "5px",
  },
  save: {
    backgroundColor: "#3059fb",
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2b50e2",
    },
  },
  cancel: {
    backgroundColor: "#f3f4f6",
    color: "#616775",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#dbdcdd",
    },
  },
  header: {
    display: "flex",
    fontWeight: 500,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#FFFFFF",
    backgroundColor: "#333232",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 10,
    marginTop: 20,
    paddingTop: 20,
    borderTop: "1px solid #00000029",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
});

const SavePresetModal = ({ open, handleClose, filters }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const [data, setData] = useState([]);
  const [enableButton, setEnableButton] = useState(true);

  const onChangeLocal = (newData) => {
    setData({ ...data, ...newData });
  };

  const onChangeField = (e) => {
    const { name, value } = e.target;
    onChangeLocal({ [name]: value });
  };

  const onCreate = () => {
    setEnableButton(false);

    const params = {
      name: data.name,
      filters: {
        user_id: filters.user_id,
        user_shift: filters.user_shift,
        // start_date: moment(filters.startDate).format("YYYY-MM-DD"),
        // end_date: moment(filters.endDate).format("YYYY-MM-DD"),
        task_category_id: filters.task_category_id,
        parent_task_category_id: filters.parent_task_category_id,
        firm_ids: filters.firm_ids,
        account_id: filters.account_id,
        location_id: filters.location_id,
      },
      report_type: "user_data",
    };

    doAuthenticatedPost(API_REPORT_PRESETS, params, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
      catch: () => {
        setEnableButton(true);
      },
    });
  };

  const onError = (res) => {
    let errorMessage = "Unable to create:";
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += " " + res.errors.join(" ");
    }
    prompt(errorMessage, {
      variant: "error",
    });

    setEnableButton(true);
  };

  const onSuccess = (res) => {
    const message = `Successfully created preset: ${data.name}`;
    prompt(message);

    setEnableButton(true);
    onClose();
  };

  const onClose = () => {
    handleClose();
  };

  return (
    <Modal open={open}>
      <Box className={classes.box}>
        <div className={classes.header}>Save Filters as Preset</div>
        <div className={classes.container}>
          <div className={classes.textFieldContainer}>
            <div className={classes.wrapper}>
              <TextField
                size="small"
                variant="outlined"
                label="Preset Name"
                name="name"
                value={data.name}
                onChange={onChangeField}
              />
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={classes.cancel}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={!enableButton}
              variant="contained"
              className={classes.save}
              onClick={onCreate}
            >
              Save
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SavePresetModal;
