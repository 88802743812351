import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: "5px !important",
    marginRight: "5px !important",
  },
  dropzone: {
    overflow: "hidden !important",
  },
}));

export default ({ url, afterSave, onCancel }) => {
  const classes = useStyles();
  const windowGlobal = typeof window !== "undefined" && window;
  var token = windowGlobal.localStorage.getItem("billebotAuthToken");
  const getUploadParams = ({ meta }) => {
    const headers = { Authorization: `Bearer ${token}` };
    return { url: url, headers: headers };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
    if (status === "done") {
      // do something here
      afterSave();
    } else if (status === "error_upload") {
      alert("Please try again...");
    }
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };

  const UploadPhotoDropzone = () => (
    <div>
      <Dropzone
        className={classes.dropzone}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        accept="image/*"
        maxFiles={1}
        multiple={false}
        SubmitButtonComponent={(props) => null}
      />
      <div>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.button}
          startIcon={<CancelIcon />}
          onClick={onCancel}
        >
          Close
        </Button>
      </div>
    </div>
  );

  return <UploadPhotoDropzone />;
};
