import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import DropdownUsers from "../../../../../../../manage/firms/components/manage-users/DropdownUsers";
import { useGlobal } from "../../../../../../../context/global";

const LABEL = "Work in Minutes";

const useStyles = makeStyles((theme) => ({
  element_row: {
    margin: 7,
    display: "flex",
    flexDirection: "column",
  },
  textfield: {
    width: "100%",
  },
  control: {
    width: "100%",
    marginRight: "10px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 7,
  },
  addButton: {
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#3587e4",
    },
  },
  minusButton: {
    fontSize: "18px",
    marginLeft: "10px",
    backgroundColor: "#c5564e",
    "&:hover": {
      backgroundColor: "#e9675e",
    },
  },
}));

export default ({ onSubmit }) => {
  const classes = useStyles();
  const { myself, firmUsers } = useGlobal();
  const [value, setValue] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const workMinuteEmpty = value === "";
  const userEmpty = selectedUser === null;
  const inputsEmpty = userEmpty || workMinuteEmpty;

  const onClick = (e, action) => {
    if (value === "") return;

    const actionSymbol = action === "subtract" ? "-" : "+";
    const comment_value = `${actionSymbol}${value} work`;
    onSubmit(comment_value, myself.id, selectedUser.value);
    setValue("");
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className={classes.element_row}>
      <TextField
        label={LABEL}
        size="small"
        type="number"
        className={classes.textfield}
        InputProps={{
          endAdornment: <InputAdornment position="start">mins</InputAdornment>,
          inputMode: "numeric",
          pattern: "[0-9]",
        }}
        value={value}
        onChange={onChange}
      />
      <div className={classes.container}>
        <DropdownUsers
          users={firmUsers}
          label={"In Behalf Of"}
          styles={{ width: "100%" }}
          controlClassName={classes.control}
          setSelectedUser={setSelectedUser}
        />
        <Button
          variant="contained"
          size="small"
          className={classes.addButton}
          onClick={(e) => onClick(e, "add")}
          disabled={inputsEmpty}
        >
          +
        </Button>
        <Button
          variant="contained"
          size="small"
          className={classes.minusButton}
          onClick={(e) => onClick(e, "subtract")}
          disabled={inputsEmpty}
        >
          -
        </Button>
      </div>
    </div>
  );
};
