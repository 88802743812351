import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useFirmsCtx } from "../context";

import { Spinner } from "../../../../shared/components/utils/_spinner";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import AddFirm from "./_add_firm";
import Firms from "./_firms";
import FirmFilters from "./_filters";
import NoResults from "../../../../shared/components/utils/_no_results";

import { IfAnyGranted } from "react-authorization";
import { usePermission } from "../../../context/permission";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: "25px",
  },
}));

export default () => {
  const classes = useStyles();
  const { noResult, isLoading } = useFirmsCtx();
  const { atleastAngel, getMyRoles } = usePermission();

  const renderContent = () => {
    if (isLoading === "primary") {
      return <Spinner height={700} />;
    }

    if (noResult) {
      return <NoResults />;
    }

    return <Firms />;
  };

  return (
    <>
      <Typography variant="h5">Firms</Typography>
      <div className={classes.datagrid_wrapper}>
        <Paper className={classes.paper}>
          <FirmFilters />
          <Box>
            <IfAnyGranted expected={atleastAngel()} actual={getMyRoles()}>
              <AddFirm />
            </IfAnyGranted>
          </Box>
        </Paper>
        {renderContent()}
      </div>
    </>
  );
};
