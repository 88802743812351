import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  Button,
} from "@mui/material";
import { ACCOUNTING_TASK_ACKNOWLEDGE } from "../../../../../../../../const/api_paths";
import { doAuthenticatedPost } from "../../../../../../../../actions/_methods";
import { useGlobal } from "../../../../../../../context/global";
import { useFirmTasks } from "../../../../../../../context/firm_tasks";
import { set } from "lodash";

const useStyles = makeStyles({
  container: {
    maxHeight: 335,
    marginTop: 20,
    marginBottom: 40,
    overflow: "auto",
  },
  link: {
    color: "#0066cc",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const columnWidths = [
  "10%", // Version
  "15%", // Type
  "35%", // Link
  "30%", // Notes
  "15%", //Acknowledge
];

const columHeight = "69.5px";

const AcknowledgementList = ({ task, items }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { fetchReferences } = useFirmTasks();
  const [enableAcknowledge, setEnableAcknowledge] = useState(false);

  const onClick = (e, item) => {
    doAuthenticatedPost(
      ACCOUNTING_TASK_ACKNOWLEDGE,
      {
        version_id: item.id,
      },
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => alert("Please try again"),
      }
    );
  };

  const onSuccess = (res) => {
    const message = "Successfully acknowledged.";

    fetchReferences(task);
    prompt(message);
  };

  const formatUrl = (url) => {
    if (/^https?:\/\//i.test(url)) {
      return url;
    } else {
      return `https://${url}`;
    }
  };

  const handleSopClick = (e) => {
    setEnableAcknowledge(true);
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: columnWidths[0] }}>Version</TableCell>
            <TableCell style={{ width: columnWidths[1] }}>Type</TableCell>
            <TableCell style={{ width: columnWidths[2] }}>Link</TableCell>
            <TableCell style={{ width: columnWidths[3] }}>Notes</TableCell>
            <TableCell style={{ width: columnWidths[4] }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id}>
              <TableCell
                component="th"
                scope="row"
                style={{ height: columHeight, width: columnWidths[0] }}
              >
                {item.version}
              </TableCell>
              <TableCell
                style={{ height: columHeight, width: columnWidths[1] }}
              >
                {item.reference_type || "N/A"}
              </TableCell>
              <TableCell
                style={{ height: columHeight, width: columnWidths[2] }}
              >
                <a
                  href={formatUrl(item.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleSopClick}
                  className={classes.link}
                >
                  {item.link}
                </a>
              </TableCell>
              <TableCell
                style={{ height: columHeight, width: columnWidths[3] }}
              >
                {item.notes || "N/A"}
              </TableCell>
              <TableCell align="right" style={{ width: columnWidths[4] }}>
                <Tooltip title="Make sure you have read the new SOP before clicking Acknowledge.">
                  <span>
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={(e) => onClick(e, item)}
                      disabled={!enableAcknowledge}
                    >
                      Acknowledge
                    </Button>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AcknowledgementList;
