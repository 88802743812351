import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((_theme) => ({
  title: {
    fontSize: "1rem;",
    // fontWeight: "bold",
  },
  link: {
    fontSize: "1rem",
    textDecoration: "none",
  },
}));

export default ({ task }) => {
  const classes = useStyles();
  const url = task.latest_sop_documents_url;

  if (!url) {
    return null;
  }

  return (
    <div>
      <span className={classes.title}>Latest SOP Documents: </span>
      <span>
        <a href={url} target="_blank" rel="noreferrer" className={classes.link}>
          {url}
        </a>
      </span>
    </div>
  );
};
