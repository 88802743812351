import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { doAuthenticatedDelete } from "../../../actions/_methods";
import { API_KNOWLEDGE_BASE } from "../../../const/api_paths";
import { useGlobal } from "../../context/global";

const useStyles = makeStyles({
  actionButton: {
    marginRight: 20,
  },
});

const Delete = ({ item, onRecordDeleted }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const [enableButton, setEnableButton] = useState(true);

  const onDelete = () => {
    const url = API_KNOWLEDGE_BASE.replace(":id", item.id);

    if (window.confirm(`Are you sure you want to delete ${item.title}?`)) {
      setEnableButton(false);

      doAuthenticatedDelete(
        url,
        {},
        {
          success: onSuccess,
          general: onSuccess,
          error: onError,
        }
      );
    }
  };

  const onError = (_res) => {
    setEnableButton(true);
  };

  const onSuccess = (_res) => {
    const message = `${item.title} has been deleted.`;
    prompt(message);

    onRecordDeleted();
  };

  return (
    <Button
      size="small"
      variant="contained"
      color="error"
      className={classes.actionButton}
      endIcon={<DeleteForeverIcon />}
      disabled={!enableButton}
      onClick={onDelete}
    >
      Delete
    </Button>
  );
};

export default Delete;
