import React from "react";
import { Box, Typography } from "@mui/material";
import _ from "lodash";

import CategoryTooltip from "../../../../../../../shared/components/CategoryTooltip";

export default ({ task }) => {
  return (
    <Box sx={{width: "fit-content"}}>
      <CategoryTooltip title={task.task_category_description}>
        <Typography variant="body2" sx={{ color: "info.dark" }}>
          {_.compact([
            task.task_category_parent_name,
            task.task_category_sub_name,
          ]).join(" >> ")}
        </Typography>
      </CategoryTooltip>
    </Box>
  );
};
