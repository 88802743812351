import React, { useState, useEffect, useRef } from "react";
import Stack from "@mui/material/Stack";
import { Box, Button, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import { API_MANAGE_TASK_REQUEST_BULK_APPROVE } from "../../../../const/api_paths";
import {
  doAuthenticatedGet,
  doAuthenticatedPost,
} from "../../../../actions/_methods";
import Row from "./_row";
import { STYLES } from "../../../../shared/components/data_table/styles";
import { Spinner } from "../../../../shared/components/utils/_spinner";

const useStyles = makeStyles(() => ({}));

const COMPONENT_STYLES = {
  header: {
    marginBottom: "25px",
    marginTop: "25px",
  },
  bulkApproveButton: {
    marginBottom: "25px",
    marginTop: "25px",
  },
};

export default (props) => {
  const { api_path, peer_name, columns, listable_columns, filters } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enableBulkApproveButton, setenableBulkApproveButton] = useState(true);
  const [page, setPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const tableRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const showBulkApproveButton = !["approved"].includes(filters["exact_status"]);

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = (page = 1) => {
    setLoading(true);
    doAuthenticatedGet(
      api_path,
      { ...filters, ...{ page: page } },
      {
        general: onGeneral,
        success: onSuccess,
        error: onError,
      }
    );
  };

  const onPaginate = (event, value) => {
    setPage(value);
    loadData(value);
  };

  const onGeneral = (res) => {
    // do nothing for now
    setLoading(false);
  };

  const onSuccess = (res) => {
    setTotalPages(res["total_pages"]);
    setData(res[peer_name]);
    setLoading(false);
  };

  const onError = (res) => {
    alert("Some error");
    setLoading(false);
  };

  const TableHeaders = () => (
    <thead>
      <tr>
        <th style={STYLES.th} key={`datatable_th_approve_checkbox}`}>
          Approve
        </th>
        {listable_columns.map((col) => {
          return (
            <th style={STYLES.th} key={`datatable_th_${Math.random()}`}>
              {col["header"]}
            </th>
          );
        })}
        <th style={STYLES.th_end}>Action</th>
      </tr>
    </thead>
  );

  const onBulkApproveClick = () => {
    const checked = tableRef.current.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    const selectedIds = Array.from(checked).map((checkbox) => checkbox.value);

    if (selectedIds.length === 0) {
      enqueueSnackbar("Please select at least one task request to approve.", {
        variant: "error",
      });
      return;
    } else {
      setenableBulkApproveButton(false);
      doAuthenticatedPost(
        API_MANAGE_TASK_REQUEST_BULK_APPROVE,
        { ids: selectedIds },
        {
          success: onSuccessApprove,
          general: onSuccessApprove,
          error: onErrorApprove,
        }
      );
    }
  };

  const onSuccessApprove = (res) => {
    setenableBulkApproveButton(true);
    enqueueSnackbar("Task requests have been approved.", {
      variant: "success",
    });
    loadData();
  };

  const onErrorApprove = (res) => {
    setenableBulkApproveButton(true);
    enqueueSnackbar("An ERROR occured when bulk approving, please try again.", {
      variant: "error",
    });
  };

  return (
    <div>
      {loading ? (
        <Spinner height={300} />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" style={COMPONENT_STYLES.header}>
              Task Requests
            </Typography>
            <Box height="2rem">
              {showBulkApproveButton && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddTaskIcon />}
                  className={classes.button}
                  onClick={onBulkApproveClick}
                  disabled={!enableBulkApproveButton}
                  style={COMPONENT_STYLES.bulkApproveButton}
                >
                  Bulk Approve
                </Button>
              )}
            </Box>
          </Box>
          <table style={STYLES.table} cellSpacing="0" ref={tableRef}>
            <TableHeaders />
            <tbody>
              {data.map((row) => {
                return (
                  <Row
                    key={`datatable_row_${Math.random()}`}
                    data={row}
                    columns={columns}
                    listable_columns={listable_columns}
                    loadData={loadData}
                  />
                );
              })}
            </tbody>
          </table>
          <Stack mt={2}>
            <Pagination
              count={total_pages}
              page={page}
              onChange={onPaginate}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </>
      )}
    </div>
  );
};
