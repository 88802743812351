import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, TextField, Autocomplete } from "@mui/material";

import { doAuthenticatedGet } from "../../../../actions/_methods";
import { ACCOUNTING_PROJECT_TASKS_BY_ORG } from "../../../../const/api_paths";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "inline-block",
    marginRight: "2px",
  },
  input: {
    width: "610px",
    marginRight: "10px",
  },
}));

const option_style = {
  display: "flex",
  fontSize: "11pt",
  padding: "10px",
  cursor: "pointer",
  borderBottom: "1px solid #555",
  marginBottom: "0",
};

const name = "sop_update_att_id";
const title = "Accounting Task Template for SOP";

export default ({ data, onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [accountingProjectTasks, setAccountingProjectTasks] = useState([]);
  const params = {
    firm_id: data.firm_id,
    account_id: data.account_id ?? "",
    location_id: data.location_id ?? "",
  };

  useEffect(() => {
    setValue(null);
    fetchAccountingProjectTasks();
  }, [data.firm_id, data.account_id, data.location_id]);

  const fetchAccountingProjectTasks = () => {
    doAuthenticatedGet(ACCOUNTING_PROJECT_TASKS_BY_ORG, params, {
      success: onAccountingProjectTaskSuccess,
      general: onAccountingProjectTaskSuccess,
      error: (res) => console.error(res),
    });
  };

  const onAccountingProjectTaskSuccess = (res) => {
    setAccountingProjectTasks(res.task_templates);
  };

  const onChangeLocal = (e, v) => {
    setValue(v);
    onChange(sendBackData(v));
  };

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value?.id;
    return data;
  };

  const OptionComponent = ({ option, props }) => {
    return (
      <div style={option_style} {...props}>
        {option.label}
      </div>
    );
  };

  const InputComponent = ({ params, title }) => (
    <TextField {...params} label={title} variant="outlined" />
  );

  return (
    <Box className={classes.box}>
      <Autocomplete
        size="small"
        options={accountingProjectTasks}
        value={value}
        limitTags={1}
        className={classes.input}
        onChange={onChangeLocal}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <OptionComponent {...{ option, props }} />
        )}
        renderInput={(params) => <InputComponent {...{ params, title }} />}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Box>
  );
};
