import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Modal, Stack } from "@mui/material";

import {
  API_REPORT_PRESETS,
  API_REPORT_PRESET,
} from "../../../../const/api_paths";
import {
  doAuthenticatedGet,
  doAuthenticatedDelete,
} from "../../../../actions/_methods";
import { useGlobal } from "../../../context/global";

const useStyles = makeStyles({
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#f8f8ff",
    borderRadius: "5px",
  },
  cancel: {
    backgroundColor: "#f3f4f6",
    color: "#616775",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#dbdcdd",
    },
  },
  header: {
    display: "flex",
    fontWeight: 500,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#FFFFFF",
    backgroundColor: "#333232",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 10,
    marginTop: 20,
    paddingTop: 20,
    borderTop: "1px solid #00000029",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  presetContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  preset: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const ListPresetsModal = ({ open, handleClose, setFilters }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadPresets = () => {
    doAuthenticatedGet(
      API_REPORT_PRESETS,
      { report_type: "user_data" },
      {
        success: onSuccessLoad,
        general: onSuccessLoad,
        error: onErrorLoad,
      }
    );
  };

  useEffect(() => {
    if (open) {
      loadPresets();
    }
  }, [open]);

  const handleError = (res, errorMessage) => {
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += ": " + res.errors.join(" ");
    }
    prompt(errorMessage, {
      variant: "error",
    });
  };

  const onClose = () => {
    handleClose();
  };

  const onSuccessLoad = (data) => {
    setData(data.report_presets);
    setLoading(false);
  };

  const onErrorLoad = (res) => {
    handleError(res, "Unable to load presets");
    setLoading(false);
  };

  const onDelete = (preset) => () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the preset ${preset.name}?`
    );

    if (confirmed) {
      const url = API_REPORT_PRESET.replace(":id", preset.id);
      doAuthenticatedDelete(
        url,
        {},
        {
          success: onSuccessDelete,
          general: onSuccessDelete,
          error: onErrorDelete,
        }
      );
    }
  };

  const onSuccessDelete = (res) => {
    prompt("Preset deleted successfully", {
      variant: "success",
    });
    loadPresets();
  };

  const onErrorDelete = (res) => {
    handleError(res, "Unable to delete preset");
  };

  const onLoad = (preset) => () => {
    const confirmed = window.confirm(
      `Are you sure you want to load the preset ${preset.name}?`
    );

    if (confirmed) {
      setFilters(preset.filters);
      onClose();
    }
  };

  return (
    <Modal open={open}>
      <Box className={classes.box}>
        <div className={classes.header}>My Presets</div>
        <div className={classes.container}>
          <div className={classes.presetContainer}>
            <Stack spacing={2}>
              {loading && <div>Loading Presets...</div>}
              {!loading && data.length === 0 && (
                <div>
                  No presets found. Create one by selecting the filters and
                  clicking Save New Preset!
                </div>
              )}
              {!loading &&
                data.map((preset) => (
                  <Stack key={preset.id} direction="row" spacing={2}>
                    <Stack width="70%" className={classes.preset}>
                      {preset.name}
                    </Stack>
                    <Stack width="30%">
                      <Stack
                        className={classes.actionButtons}
                        direction="row"
                        spacing={2}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          width="30px"
                          onClick={onLoad(preset)}
                        >
                          Load
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          width="30px"
                          onClick={onDelete(preset)}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
            </Stack>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={classes.cancel}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ListPresetsModal;
