import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";

import Description from "./_Description";

const useStyles = makeStyles((_theme) => ({
  cell_large: {
    width: "300px",
  },
  cell: {
    width: "130px",
    textAlign: "center",
  },
}));

export default ({ results, date_range_str, showInitialMessage }) => {
  const classes = useStyles();
  const [local_results, setLocalResults] = useState([]);

  useEffect(() => {
    setLocalResults(results);
  }, [results]);

  return (
    <>
      <Typography gutterBottom variant="h6" component="div">
        {date_range_str}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 900 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>Employee ID</TableCell>
              <TableCell className={classes.cell}>Employee Name</TableCell>
              <TableCell className={classes.cell}>Task ID</TableCell>
              <TableCell className={classes.cell}>Date</TableCell>
              <TableCell className={classes.cell}>Firm</TableCell>
              <TableCell className={classes.cell}>Account</TableCell>
              <TableCell className={classes.cell}>Locations</TableCell>
              <TableCell className={classes.cell}>Parent</TableCell>
              <TableCell className={classes.cell}>Child</TableCell>
              <TableCell className={classes.cell}>Description</TableCell>
              <TableCell className={classes.cell}>Mins</TableCell>
              <TableCell className={classes.cell}># Hours</TableCell>
              <TableCell className={classes.cell}>Start (MNL)</TableCell>
              <TableCell className={classes.cell}>Finish (MNL)</TableCell>
              <TableCell className={classes.cell}>Start (EST)</TableCell>
              <TableCell className={classes.cell}>Finish (EST)</TableCell>
              <TableCell className={classes.cell}>Invoices</TableCell>
              <TableCell className={classes.cell}>Rejects</TableCell>
              <TableCell className={classes.cell}>Pages</TableCell>
              <TableCell className={classes.cell}>Shift</TableCell>
              <TableCell className={classes.cell}>Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {local_results.length === 0 && (
              <TableRow>
                <TableCell colSpan={21} align="center">
                  {showInitialMessage
                    ? "Please click 'Filter' or 'Download Report' to load the data."
                    : "No data found for the selected filters."}
                </TableCell>
              </TableRow>
            )}
            {local_results.map((result, _indx) => (
              <TableRow
                key={`tr_${result.indx}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.cell}>
                  {result.employee_id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {result.employee_name}
                </TableCell>
                <TableCell className={classes.cell}>{result.task_id}</TableCell>
                <TableCell className={classes.cell}>{result.date}</TableCell>
                <TableCell className={classes.cell}>{result.firm}</TableCell>
                <TableCell className={classes.cell}>{result.account}</TableCell>
                <TableCell className={classes.cell}>
                  {result.locations}
                </TableCell>
                <TableCell className={classes.cell}>{result.parent}</TableCell>
                <TableCell className={classes.cell}>{result.child}</TableCell>
                <TableCell className={classes.cell}>
                  <Description result={result} />
                </TableCell>
                <TableCell className={classes.cell}>{result.mins}</TableCell>
                <TableCell className={classes.cell}>{result.hours}</TableCell>
                <TableCell className={classes.cell}>{result.start_mnl}</TableCell>
                <TableCell className={classes.cell}>{result.finish_mnl}</TableCell>
                <TableCell className={classes.cell}>{result.start_est}</TableCell>
                <TableCell className={classes.cell}>{result.finish_est}</TableCell>
                <TableCell className={classes.cell}>
                  {result.invoices}
                </TableCell>
                <TableCell className={classes.cell}>{result.rejects}</TableCell>
                <TableCell className={classes.cell}>{result.pages}</TableCell>
                <TableCell className={classes.cell}>{result.shift}</TableCell>
                <TableCell className={classes.cell}>{result.tags}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
