import React from "react";
import Box from "@mui/material/Stack";
import linkifyHtml from "linkify-html";
import "react-quill/dist/quill.snow.css";

export default ({ description }) => {

  const formatText = (text) => {
    if (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    } else {
      return text;
    }
  };

  const linkified = () => {
    if (description) {
      return linkifyHtml(formatText(description), { target: "_blank" });
    } else {
      return "";
    }
  };

  return (
    <Box>
      <div className="ql-editor">
        <span dangerouslySetInnerHTML={{ __html: linkified() }} />
      </div>
    </Box>
  );
};
