import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1rem;",
    fontWeight: "bold",
  },
  processing: {
    fontStyle: "italic",
    fontSize: "1rem",
  },
  link: {
    fontSize: "1rem",
  },
}));

export default ({ task }) => {
  const classes = useStyles();
  const url = task.task_sop_upload_url;
  const isProcessing = url === "processing";

  if (!url) {
    return null;
  }

  return (
    <div>
      <span className={classes.title}>Uploaded SOP Documents: </span>
      {isProcessing ? (
        <span className={classes.processing}>Processing...</span>
      ) : (
        <span className={classes.link}>
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        </span>
      )}
    </div>
  );
};
