import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";

import InputAdjustment from "../../_default/info/add_comment/InputAdjustment";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    diplay: "flex",
    flexGrow: 1,
    marginTop: "10px",
  },
  card_wrapper: {
    padding: "10px",
  },
}));

export default ({ onSubmit }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Card variant="outlined" className={classes.card_wrapper}>
        <InputAdjustment
          onSubmit={onSubmit}
          label="Break in Minutes"
          fieldUnit="mins"
          commentUnit="breaks"
        />
        <InputAdjustment
          onSubmit={onSubmit}
          label="Reporting in Minutes"
          fieldUnit="mins"
          commentUnit="reporting"
        />
        <InputAdjustment
          onSubmit={onSubmit}
          label="Invoice Count"
          fieldUnit="invoices"
          commentUnit="invoices"
        />
        <InputAdjustment
          onSubmit={onSubmit}
          label="Invoice Pages"
          fieldUnit="pages"
          commentUnit="pages"
        />
        <InputAdjustment
          onSubmit={onSubmit}
          label="Rejected Pages"
          fieldUnit="pages"
          commentUnit="rejects"
        />
      </Card>
    </div>
  );
};
