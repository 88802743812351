import loadable from "@loadable/component";
import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles((_theme) => ({
  box: {
    display: "flex",
    flexGrow: 1,
    marginRight: "2px",
    marginBottom: "50px",
    width: "100%",
  },
  input: {
    minHeight: "50px",
    width: "100%",
  },
}));

const name = "description";

const ReactQuill = loadable(() => import("react-quill"));

export default ({ defaultValue, onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, []);

  const onChangeLocal = (e) => {
    setValue(e);
    onChange(sendBackData(e));
  };

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];

  return (
    <Box className={classes.box}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChangeLocal}
        modules={modules}
        formats={formats}
        className={classes.input}
      />
    </Box>
  );
};
