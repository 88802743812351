import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Modal } from "@mui/material";
import FilterDropdown from "../../FilterDropdown";
import { referenceTypeOptions } from "./utils";
import FilterButtons from "../../FilterButtons";
import { doAuthenticatedGet } from "../../../../../../../actions/_methods";
import { ACCOUNTING_TASK_REFERENCES } from "../../../../../../../const/api_paths";
import ReferenceList from "./ReferenceList";
// import AddReference from "./add-reference/AddReference";
import ErrorMessage from "../../../../../../reports/data_reports/report/ErrorMessage";
import { Spinner } from "../../../../../../../shared/components/utils/_spinner";
import ManageReference from "./manage-reference/ManageReference";

const useStyles = makeStyles({
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    backgroundColor: "#f8f8ff",
    padding: 20,
    borderRadius: "5px",
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
  },
  name: {
    fontSize: 20,
    marginLeft: 10,
    color: "#00000069",
  },
  text: {
    width: "100%",
  },
});

const ManageCard = ({ open, task, handleClose }) => {
  const classes = useStyles();
  const [references, setReferences] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddReference, setShowAddReference] = useState(false);
  const [selectedReference, setSelectedReference] = useState(null);
  const [showManageReference, setShowManageReference] = useState(false);
  const [referenceType, setReferenceType] = useState(referenceTypeOptions[0]);

  const handleChange = (event) => {
    const value = event.target.value === "" ? null : event.target.value;
    setReferenceType(value);
  };

  const fetchReferences = () => {
    setIsLoading(true);
    setReferences([]);

    doAuthenticatedGet(
      ACCOUNTING_TASK_REFERENCES,
      {
        accounting_project_task_id: task.id,
        reference_type: referenceType.value,
      },
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => alert("Please try again"),
      }
    );
  };

  const onSuccess = (res) => {
    setReferences(res.references);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchReferences();
  }, [referenceType]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <div style={{ marginBottom: 20 }}>
          <span className={classes.header}>Manage SOPs:</span>
          <span className={classes.name}>{task?.name}</span>
        </div>
        <FilterDropdown
          width="25%"
          value={referenceType}
          label="Reference Type"
          options={referenceTypeOptions}
          onChange={handleChange}
        />

        {isLoading ? (
          <Spinner height={200} />
        ) : references.length > 0 ? (
          <ReferenceList
            references={references}
            setSelectedReference={setSelectedReference}
            setShowManageReference={setShowManageReference}
          />
        ) : (
          <ErrorMessage
            height={200}
            message="There are no references yet of this type for this task."
          />
        )}
        <FilterButtons
          labelCancel="Close"
          labelSave="Add Reference"
          showSave={false}
          onClickSave={() => setShowAddReference(true)}
          onClickCancel={handleClose}
        />
        {/* <AddReference
          task={task}
          open={showAddReference}
          referenceType={referenceType}
          fetchReferences={fetchReferences}
          handleClose={() => setShowAddReference(false)}
        /> */}

        <ManageReference
          open={showManageReference}
          reference={selectedReference}
          fetchReferences={fetchReferences}
          handleClose={() => setShowManageReference(false)}
        />
      </Box>
    </Modal>
  );
};

export default ManageCard;
