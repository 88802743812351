import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
  TextField,
  Button,
  Box,
} from "@mui/material";
import {
  doAuthenticatedPut,
  doAuthenticatedDelete,
} from "../../../../../../../actions/_methods";
import { API_TASK_UPDATE_CHECKLIST } from "../../../../../../../const/api_paths";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((_theme) => ({
  box: {
    marginRight: "2px",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  item: {
    paddingTop: "0",
    paddingBottom: "0",
    marginTop: "0",
    marginBottom: "0",
  },
  item_done: {
    textDecoration: "line-through",
  },
  list_item: {
    paddingTop: "1px",
  },
  check_icon: {
    fontSize: "medium",
    color: "green",
    minWidth: "28px",
    padding: "0",
  },
  input: {
    width: "73%",
    marginLeft: "30px",
    size: "small",
  },
}));

export default ({ default_value, indx, onDelete, onChangeRow }) => {
  const classes = useStyles();
  const [row_data, setRowData] = useState(default_value);
  const [status, setStatus] = useState(default_value.status);
  const [name, setName] = useState(default_value.name);
  const [editMode, setEditMode] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setRowData(default_value);
  }, [default_value]);

  const updateChecklist = (new_val) => {
    let api_path = API_TASK_UPDATE_CHECKLIST.replace(
      ":task_id",
      default_value.task_id
    ).replace(":id", row_data.id);

    doAuthenticatedPut(api_path, new_val, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  };

  const onChange = (e) => {
    let new_stats = !status;
    updateChecklist({ status: new_stats });
    setStatus(new_stats);
  };

  const onEdit = (e) => {
    setName(e.target.value);
  };

  const onSave = (e) => {
    updateChecklist({ name: name });
    setEditMode(false);
  };

  const onDeleteLocal = (e) => {
    let api_path = API_TASK_UPDATE_CHECKLIST.replace(
      ":task_id",
      default_value.task_id
    ).replace(":id", default_value.id);

    if (window.confirm("Are your sure you want to delete this checklist?")) {
      doAuthenticatedDelete(
        api_path,
        {},
        {
          success: onSuccessDelete,
          general: onSuccessDelete,
          error: onError,
        }
      );
    }
  };

  const onSuccess = (res) => {
    let params = { ...row_data, ...res };
    setRowData({});
    setRowData(params);
    onChangeRow(params); // Update the value from the parent component
    enqueueSnackbar("You have successfully UPDATED task checklist.", {
      variant: "success",
    });
  };

  const onSuccessDelete = (res) => {
    onDelete(indx);
    enqueueSnackbar("You have successfully DELETED task checklist.", {
      variant: "success",
    });
  };

  const onError = (res) => {
    enqueueSnackbar("An ERROR occured, please try again.", {
      variant: "error",
    });
  };

  const DisplayRow = () => {
    return (
      <ListItem
        key={default_value.id}
        className={classes.list_item}
        secondaryAction={
          <IconButton edge="end" aria-label="comments">
            <EditIcon color="primary" onClick={() => setEditMode(true)} />
            <DeleteIcon onClick={onDeleteLocal} />
          </IconButton>
        }
      >
        <ListItemButton className={classes.item}>
          <ListItemIcon className={classes.check_icon}>
            <Checkbox
              color="success"
              edge="start"
              className={classes.check_icon}
              checked={status}
              onChange={onChange}
            />
          </ListItemIcon>
          <ListItemText
            className={`${classes.item} ${status ? classes.item_done : ""}`}
            primary={row_data.name}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <>
      {!editMode && <DisplayRow />}
      {editMode && (
        <Box className={classes.box}>
          <TextField
            key={`edit-checklist-${row_data.id}`}
            variant="outlined"
            size="small"
            className={classes.input}
            label="Name"
            value={name}
            onChange={onEdit}
          />
          <Button
            variant="contained"
            onClick={onSave}
            style={{ marginLeft: "5px" }}
          >
            Save
          </Button>
          <Button
            onClick={() => setEditMode(false)}
            style={{ marginLeft: "5px" }}
          >
            Cancel
          </Button>
        </Box>
      )}
    </>
  );
};
