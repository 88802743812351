import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  API_PATH,
  EXPORT_TASKS_BY_FIRM,
  EXPORT_TASKS_BY_FIRM_V2,
  DAILY_QUEUES_COUNT_REPORT,
} from '../../../../../../const/api_paths';
import { IfAnyGranted } from 'react-authorization';
import { usePermission } from '../../../../../context/permission';
import { useFirmTasks } from '../../../../../context/firm_tasks';
import { doAuthenticatedGet } from '../../../../../../actions/_methods';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import GoogleSheet from '../../../google_sheet/GoogleSheet';

const ITEM_HEIGHT = 48;

export default ({
  show_tasks,
  toggleHideShow,
  show_add_task,
  toggleShowAddTask,
  request_task,
  toggleRequestTask,
  filters,
  firm_id,
}) => {
  const { atleastManager, isAngel, getMyRoles } = usePermission();
  const { preferences } = useFirmTasks();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isAddTaskEnabled = preferences.enable_add_task === 'true';
  const [showModalSheet, setShowModalSheet] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHideTasks = () => {
    toggleHideShow();
    setAnchorEl(null);
  };

  const onShowTasks = () => {
    toggleHideShow();
    setAnchorEl(null);
  };

  const onShowAddTask = () => {
    toggleShowAddTask();
    setAnchorEl(null);
  };

  const onRequestTask = () => {
    toggleRequestTask();
    setAnchorEl(null);
  };

  const toggleTasks = () => {
    show_tasks ? onHideTasks() : onShowTasks();
  };

  const onExport = () => {
    filters['firm_id'] = firm_id;
    doAuthenticatedGet(EXPORT_TASKS_BY_FIRM, filters, {
      success: onSuccess,
      general: onSuccess,
      error: (res) => alert('Please try again'),
    });
  };

  const onExportV2 = () => {
    filters['firm_id'] = firm_id;
    doAuthenticatedGet(EXPORT_TASKS_BY_FIRM_V2, filters, {
      success: onSuccessV2,
      general: onSuccessV2,
      error: (res) => alert('Please try again'),
    });
  };

  const onExportDailyQueuesCount = () => {
    window.location = `${API_PATH(
      DAILY_QUEUES_COUNT_REPORT
    )}.csv?firm_id=${firm_id}`;
  };

  const onExportGoogleSheet = () => {
    setShowModalSheet(true);
  };

  const onSuccess = (res) => {
    setAnchorEl(null);
    const ws = XLSX.utils.json_to_sheet(res['tasks']);
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          'Task ID',
          'Task',
          'Account',
          'Location',
          'Due Date',
          'Status',
          'Assigned To',
          'Work Hours',
          'Billable Hours',
          'Management Est. Hours',
          'Client Est. Hours',
          'Billable',
          'Invoices',
          'Pages',
          'Description',
        ],
      ],
      { origin: 'A1' }
    );

    const max_width = res['tasks'].reduce(
      (w, r) => Math.max(w, r.name.length),
      10
    );
    ws['!cols'] = [
      { wch: 10 },
      { wch: max_width },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
    ];
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(data, res['filename']);
  };

  const onSuccessV2 = (res) => {
    setAnchorEl(null);
    const ws = XLSX.utils.json_to_sheet(res['tasks']);
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          'Task ID',
          'Task',
          'Parent Category',
          'Child Category',
          'Account',
          'Location',
          'Due Date',
          'Status',
          'Assigned To',
          'Work Hours',
          'Estimated Hours',
          'Invoices',
          'Pages',
        ],
      ],
      { origin: 'A1' }
    );

    const max_width = res['tasks'].reduce(
      (w, r) => Math.max(w, r.parent_category.length),
      10
    );
    ws['!cols'] = [
      { wch: 10 },
      { wch: max_width },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
    ];
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(data, res['filename']);
  };

  return (
    <div>
      <IconButton
        edge="start"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '22ch',
          },
        }}
      >
        <MenuItem onClick={toggleTasks}>
          {show_tasks ? 'Hide Tasks' : 'Show Tasks'}
        </MenuItem>

        {(isAngel() || isAddTaskEnabled) && (
          <IfAnyGranted expected={atleastManager()} actual={getMyRoles()}>
            <MenuItem onClick={onShowAddTask}>
              {show_add_task ? 'Hide' : 'Show'} Add Task
            </MenuItem>
          </IfAnyGranted>
        )}

        <MenuItem onClick={onRequestTask}>
          {request_task ? 'Hide' : 'Show'} Request Task
        </MenuItem>
        <MenuItem onClick={onExport}>Export to Excel</MenuItem>
        <MenuItem onClick={onExportV2}>Export to Excel v2</MenuItem>
        <MenuItem onClick={onExportDailyQueuesCount}>
          Export Daily Queues
        </MenuItem>
        <MenuItem onClick={onExportGoogleSheet}>
          Export to Google Sheet
        </MenuItem>
      </Menu>

      {showModalSheet && (
        <GoogleSheet
          open={showModalSheet}
          filters={filters}
          setAnchorEl={setAnchorEl}
          handleClose={() => setShowModalSheet(false)}
        />
      )}
    </div>
  );
};
