import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Chip, TextField, Autocomplete } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  box: {
    width: "100%",
  },
}));

const name = "tags";
const title = "Tags";

export default ({ defaultValue, onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState([]);

  const defaultTags = [
    "My Tasks",
    "Dashboard",
    "Adding Tasks",
    "Requesting Tasks",
    "User Management",
    "Role Management",
    "Firm Management",
    "Accounts and Locations",
    "Task Templates",
    "Task Requests",
    "Reports",
  ];

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const onChangeLocal = (e, target) => {
    setValue(target);
    onChange(sendBackData(target));
  };

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  };

  return (
    <Box className={classes.box}>
      <Autocomplete
        multiple
        freeSolo
        size="small"
        value={value}
        options={defaultTags}
        onChange={onChangeLocal}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label={title} variant="outlined" />
        )}
      />
    </Box>
  );
};
