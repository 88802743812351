import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const useStyles = makeStyles({
  container: {
    maxHeight: 335,
    marginTop: 20,
    marginBottom: 40,
    overflow: "auto",
  },
});

const columnWidths = [
  "20%", // Version
  "40%", // Link
  "40%", // Notes
];

const columHeight = "69.5px";

const VersionList = ({ versions }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: columnWidths[0] }}>Version</TableCell>
            <TableCell style={{ width: columnWidths[1] }}>Link</TableCell>
            <TableCell style={{ width: columnWidths[2] }}>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {versions.map((version) => (
            <TableRow key={version.id}>
              <TableCell
                component="th"
                scope="row"
                style={{ height: columHeight, width: columnWidths[0] }}
              >
                {version.version}
              </TableCell>
              <TableCell
                style={{ height: columHeight, width: columnWidths[1] }}
              >
                <Link
                  href={version.link}
                  color="inherit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {version.link}
                </Link>
              </TableCell>
              <TableCell
                style={{ height: columHeight, width: columnWidths[1] }}
              >
                {version.notes || "N/A"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VersionList;
