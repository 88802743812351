import React from 'react';
import { makeStyles } from '@mui/styles';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '5px !important',
    marginRight: '5px !important',
  },
  dropzone: {
    // overflow: 'hidden !important'
    borderImageOutset: 'null !important;',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  inputLabel: {
    fontSize: '9px !important'
  }
}));

export default ({url, onUpload, afterSave, customAccept}) => {
  const classes = useStyles();
  const getUploadParams = ({ meta }) => { return {url: url} }

  const defaultAccept = 'image/*,audio/*,video/*,.pdf'
  const accept = customAccept ? customAccept : defaultAccept
  
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, xhr }, status) => { 

    if(status === 'done') {
      let response = JSON.parse(xhr.response);
      afterSave(response);
    } else if(status === 'error_upload') {
      alert('Please try again...');
    } else if(status === 'preparing') {
      onUpload();
    }
  }
  
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log(files.map(f => f.meta))
    allFiles.forEach(f => f.remove())
  }

  const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
      <div>
        {previews}
  
        <div {...dropzoneProps}>
          {files.length < maxFiles && input}
        </div>
  
        {files.length > 0 && submitButton}
      </div>
    )
  }

  const UploadPhotoDropzone = () => (
    <Dropzone
      className={classes.dropzone}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      accept={accept}
      multiple={true}
      SubmitButtonComponent={props => null}
      LayoutComponent={Layout}
      inputContent="Drag and drop file(s) here"
      styles={{
        dropzone: {
          width: 'calc(100% - 40px)',
          height: '100px',
          border: '1px dashed #3b8efa',
          margin: '20px',
          background: '#f4f9ff'
        },
        preview: {
          color: '#ffffffb3'
        },
        dropzoneActive: { borderColor: 'green' },
      }}
    />
  )

  return <UploadPhotoDropzone/>
};
