// Given a data object, convert it to a formData object
// Used when the form accepts files on creation or update
export const constructFormData = (data) => {
  var formData = new FormData();

  for (var key in data) {
    const value = data[key];
    // If value is an array, stringify it first since we are using FormData
    if (Array.isArray(value) && value[0] instanceof File) {
      value.forEach((file, index) => {
        formData.append(`${key}[${index}]`, file);
      });
    } else if (Array.isArray(value)) {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  }

  return formData;
};
